import React, { useEffect, useState } from "react";
import styles from "./providerdata.module.css";
import { updateBcbalicense } from "../../services/ProviderService";
import { Modal, Button } from 'react-bootstrap';
import success from '../../assets/Success.png'
import Loading from "../loading/Loading";

const ProviderData = ({ providerData }) => {
    const [successApproveModal, setSuccessApproveModal] = useState(false)
    const [unverified, setUnverified] = useState([]);
    const [verified, setVerified] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (providerData?.BcbaLicensing) {
            setUnverified(providerData.BcbaLicensing.filter(item => !item.isVerified) || []);
            setVerified(providerData.BcbaLicensing.filter(item => item.isVerified) || []);
        }
    }, [providerData]);
    // Move item to the verified list
    const handleVerify = (license) => {
        setUnverified(unverified.filter((item) => item._id !== license._id));
        setVerified([...verified, license]);
    };

    // Move item back to the unverified list
    const handleUnverify = (license) => {
        setVerified(verified.filter((item) => item._id !== license._id));
        setUnverified([...unverified, license]);
    };

    // Save changes
    const handleSave = async () => {

        try {
            const verifiedData = {
                doctorId: providerData._id, // Use the actual doctor ID
                licensingIds: verified.map(license => license._id), // Get IDs from verified licenses
                isVerified: true // Set to true for verified licenses
            };
            setLoading(true); // Set loading to true before starting the API call
            // Call the update function for verified licenses
            const verifiedResponse = await updateBcbalicense(verifiedData);

            // Prepare data for unverified licenses
            const unverifiedData = {
                doctorId: providerData._id, // Use the actual doctor ID
                licensingIds: unverified.map(license => license._id), // Get IDs from unverified licenses
                isVerified: false // Set to false for unverified licenses
            };

            // Call the update function for unverified licenses
            const unverifiedResponse = await updateBcbalicense(unverifiedData);
            setLoading(false);
            setSuccessApproveModal(true);
        } catch (error) {
            console.error("Error updating licenses:", error);
        }
    };

    const reformatDate = (dateStr) => {
        if (!dateStr) return '-';

        const dateObj = new Date(dateStr);
        if (isNaN(dateObj.getTime())) return '-';

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[dateObj.getUTCMonth()];
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        return `${month} ${day}, ${year}`;
    };

    return (
        <>
            {loading && <Loading />}
            <div className={styles.container}>
                <h1>Provider Data</h1>
                <div className={styles.profile}>
                    <img src={providerData?.profileImg?.url} alt="Profile" style={{ width: "100px" }} />
                    <p><strong>Full name:</strong> {providerData?.firstName} {providerData?.lastName}</p>
                    <p><strong>Date of birth:</strong> {reformatDate(providerData?.dateOfBirth)}</p>
                    <p><strong>Gender:</strong> {providerData?.gender}</p>
                    <p><strong>Certificate:</strong> {providerData?.certificate}</p>
                    <p><strong>Telehealth:</strong> {providerData?.telehealth ? 'Yes' : 'No'}</p>
                    <p><strong>In-Person:</strong> {providerData?.inPerson ? 'Yes' : 'No'}</p>
                    <p><strong>Home Base Care:</strong> {providerData?.homeBaseCare ? 'Yes' : 'No'}</p>
                    <p><strong>Private Pay:</strong> {providerData?.insuranceAccep?.privatePay ? 'Yes' : 'No'}</p>
                </div>

                <div className={styles.licenseContainer}>
                    {/* Unverified Licenses */}
                    <div className={styles.unverifiedSection}>
                        <h2>State License to be Verified</h2>
                        {unverified.map((license) => (
                            <div key={license._id} className={styles.licenseItem}>
                                <span>
                                    {license.state}: <strong>{license.stateLicense}</strong>
                                </span>
                                <button
                                    onClick={() => handleVerify(license)}
                                    className={styles.verifyButton}
                                >
                                    +
                                </button>
                            </div>
                        ))}
                    </div>

                    {/* Verified Licenses */}
                    <div className={styles.verifiedSection}>
                        <h2>States License Verified</h2>
                        {verified.map((license) => (
                            <div key={license._id} className={styles.licenseItem}>
                                <span>
                                    {license.state}: <strong>{license.stateLicense}</strong>
                                </span>
                                <button
                                    onClick={() => handleUnverify(license)}
                                    className={styles.unverifyButton}
                                >
                                    -
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Save Changes */}
                <button onClick={handleSave} className={styles.saveButton}>
                    Save Changes
                </button>
            </div>
            <Modal show={successApproveModal} onHide={() => setSuccessApproveModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <img
                            src={success}
                            alt="licenseImg"
                            className="w-75"
                        />
                        <h5>
                            Doctor's BCBA License is update Successfully!
                        </h5>
                        <Button
                            className={styles.submitbutton}
                            onClick={() => {
                                setSuccessApproveModal(false);
                                // setApprovalStatus(null);
                            }}
                        >
                            Ok
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProviderData;
