import { qs, qsOne } from '../utils/utils';
import api from '../utils/api';

export const getDoctor = async (data) => {
    try {
        const response = await api.get(`/doctor/getdoctor/${qsOne(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}   

export const getAllDoctor = async (data) => {
    try {
        const response = await api.get(`/doctor/all-doctors?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}   

export const totalDoctor = async (data) => {
    try {
        const response = await api.get(`/doctor/all-doctors`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateDoctor = async (data, dataset) => {
    try {
        const response = await api.put(`/doctor/update/${qsOne(data)}`, dataset);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateDoctorApproval = async (data) => {
    try {
        const response = await api.patch(`/doctor/bcba-certification`, data);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateBcbalicense = async (data) => {
    try {
        const response = await api.put(`/doctor/update-licensing`, data);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getCount = async () => {
    try {
        const response = await api.get(`/get-count`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}
