import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import style from './userform.module.css'
import success from '../../assets/Success.png'
import { addFAQ, updateFAQ } from '../../services/FAQService'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../loading/Loading'
import { MdLockOutline } from 'react-icons/md'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import ReactSelect from 'react-select'
import { createUser } from '../../services/UserService'

const UserForm = ({ initialData }) => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [errors, setErrors] = useState({ firstName: '', lastName: '', email: '', mobileNo: '', password: '', role: '' });
    const [form, setForm] = useState({ firstName: '', lastName: '', email: '', mobileNo: '', password: '', role: '' });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState('');
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    useEffect(() => {
        setErrors({ firstName: '', lastName: '', email: '', mobileNo: '', password: '', role: '' });
    }, [])

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    useEffect(() => {
        setLoading(true)
        if (initialData) {
            setForm({
                firstName: '',
                lastName: '',
                email: '',
                mobileNo: '',
                password: '',
                role: ''
            });
            setIsEditMode(true);
        }
        setLoading(false)
    }, [initialData]);

    const validateForm = () => {
        const { firstName, lastName, email, mobileNo, password, role } = form;

        const newErrors = {};

        if (!firstName || firstName === "") newErrors.firstName = "The first name field is required.";
        if (!lastName || lastName === "") newErrors.lastName = "The last name field is required.";
        if (!email || email === "") newErrors.email = "The email field is required.";
        if (!mobileNo || mobileNo === "") newErrors.mobileNo = "The mobile no field is required.";
        if (!password || password === "") newErrors.password = "The password field is required.";
        if (!role || role === "") newErrors.role = "The role field is required.";
        return newErrors;
    };

    const handleOnClose = () => {
        setForm({ firstName: '', lastName: '', email: '', mobileNo: '', password: '', role: '' })
        setErrors({ firstName: '', lastName: '', email: '', mobileNo: '', password: '', role: '' })
        navigate('/faq')
    }

    const handleOnSubmit = async () => {
        const newErrors = validateForm();

        if (Object.keys(newErrors).length === 0) {
            try {
                if (isEditMode) {
                    if (Object.keys(form).length > 0) {
                        setLoading(true)
                        const response = await updateFAQ(form, { id });
                        setLoading(false)
                        if (response?.error) {
                            setError(response.error);
                        }
                        else {
                            setError('')
                            setShowSuccessModal(true);
                        }
                    }
                    else {
                        setError('No changes detected')
                    }
                }
                else {
                    try {
                        setLoading(true)
                        const response = await createUser(form);
                        setLoading(false)
                        if (response?.error) {
                            setError(response.error);
                        }
                        else {
                            setError('')
                            setShowSuccessModal(true);
                            setForm({ firstName: '', lastName: '', email: '', mobileNo: '', password: '', role: '' }); // Reset form after adding
                        }

                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.error("Error submitting user:", error);
            }
        } else {
            setErrors(newErrors);
        }
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            top: 'auto',
            left: 'auto',
            right: '0',
            bottom: 'auto',
            marginTop: '10px',
            zIndex: 9999,
        }),
        control: (provided, state) => ({
            ...provided,
            borderRadius: '80px',
            border: '1px solid #B8B8B8',
            '&:hover': {
                borderRadius: '80px',
                border: '1px solid #B8B8B8',
            },
        }),
        menuList: (provided, state) => ({
            ...provided,
            border: '1px solid #BDBDBD',
            borderRadius: '10px',
            backgroundColor: `#FFFFFF`,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isHovered ? '#EEA534' : '#FFFFFF',
            color: state.isHovered ? '#FFFFFF' : '#EEA534',
            padding: '5px',
            '&:hover': {
                backgroundColor: '#EEA534',
                color: '#FFFFFF',
            },
        }),
    };

    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className='d-lg-flex gap-3'>
                    <div className={style.formsection}>
                        <Form.Group controlId='firstName' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>First Name :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.firstName || ''}
                                onChange={(e) => setField("firstName", e.target.value)}
                                placeholder='Enter first name'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.firstName && <div className="text-danger mt-2">{errors.firstName}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='lastName' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Last Name :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.lastName || ''}
                                onChange={(e) => setField("lastName", e.target.value)}
                                placeholder='Enter last name'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.lastName && <div className="text-danger mt-2">{errors.lastName}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='email' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Email :</Form.Label>
                            <Form.Control
                                type="email"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.email || ''}
                                onChange={(e) => setField("email", e.target.value)}
                                placeholder='Enter email'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email && <div className="text-danger mt-2">{errors.email}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='mobileNo' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Mobile No :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.mobileNo || ''}
                                onChange={(e) => setField("mobileNo", e.target.value)}
                                placeholder='Enter mobile no'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.mobileNo && <div className="text-danger mt-2">{errors.mobileNo}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={`mt-4 text-start`} controlId="password">
                            <Form.Label className={`${style.label} mb-3`}>Password</Form.Label>
                            <InputGroup style={{ width: '500px' }} className={`${style.passwordfield}`}>
                                <Form.Label className={`${style.inputbtn}`}><MdLockOutline /></Form.Label>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    onChange={(e) => setField("password", e.target.value)}
                                    value={form?.password || ''}
                                    className={`${style.inputfield}`}
                                    placeholder="Enter password"
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={togglePasswordVisibility}
                                    className={`${style.eybtn}`}
                                >
                                    {!showPassword ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <FaEye />
                                    )}
                                </Button>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">
                                {errors.password && <div className="text-danger mt-2">{errors.password}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='role' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Select Role :</Form.Label>
                            <ReactSelect
                                aria-label="Default select example"
                                className={`${style.inputfieldselect} mt-sm-3 mt-1`}
                                styles={customStyles}
                                options={[
                                    { value: 'user', label: 'User' },  // Added User option
                                    { value: 'admin', label: 'Admin' }  // Added Admin option
                                ]}
                                value={{ value: form?.role, label: form?.role ? form?.role : 'Select Role' }}
                                onChange={(selectedOption) => setField('role', selectedOption?.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.role && <div className="text-danger mt-2">{errors?.role}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                {error && <div className="text-danger mt-2 mt-4">{error}</div>}
                <div className='d-flex  gap-3  mt-4'>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        {isEditMode ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false}>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '200px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>{isEditMode ? 'FAQ updated successfully' : 'You have successfully Created a new User'}</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => {
                        setShowSuccessModal(false);
                        navigate('/users')
                    }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UserForm