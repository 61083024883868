import React from 'react'
import LoginAd from '../components/loginad/LoginAd'

const login = () => {
    return (
        <>
            <LoginAd />

        </>
    )
}

export default login