import React, { useEffect } from 'react'
import UserForm from '../components/userform/UserForm'

const AddUser = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Add User')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <UserForm />
                </div>
            </div>
        </>
    )
}

export default AddUser