import React, { useEffect } from 'react'
import NotificationForm from '../components/notificationform/NotificationForm'

const Notification = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Send Email')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <NotificationForm />
                </div>
            </div>
        </>
    )
}

export default Notification