import { qs, qsOne } from '../utils/utils';
import api from '../utils/api';

export const getFAQ = async (data) => {
    try {
        const response = await api.get(`/faq/getallfaqs`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getOneFAQ = async (data) => {
    try {
        const response = await api.get(`/faq/getfaq/${qsOne(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateFAQ = async (data,params) => {
    try {
        const response = await api.put(`/faq/update/${qsOne(params)}`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const deleteOneFAQ = async (data) => {
    try {
        const response = await api.delete(`/faq/delete/${qsOne(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const addFAQ = async (data) => {
    try {
        const response = await api.post(`/faq/create`, data);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return { error: error.response.data.message };
    }
}

export const searchFAQ = async (data) => {
    try {
        const response = await api.get(`/search-faq?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}