import React, { useEffect, useState } from 'react'
import DashboardTable from '../components/dashboardtable/DashboardTable'
import { getDoctor } from '../services/ProviderService'
import { useParams } from 'react-router-dom'
import ProviderData from '../components/providerdata/ProviderData'

const ProviderDetails = ({ setHeading }) => {
  const { id } = useParams()
  const [providerData, setProviderData] = useState()
  const [loading, setLoading] = useState()

  const getProviderDetails = async () => {
    try {
      const params = {
        id: id
      }
      setLoading(true)
      const response = await getDoctor(params);
      console.log(response, 'response');
      
      setProviderData(response.doctor);
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProviderDetails()
  }, [])

  useEffect(() => {
    setHeading('Providers')
  }, [])
  
  return (
    <>
      <div>
        <div className={`maindiv`}>
          <ProviderData providerData={providerData}/>
        </div>
      </div>
    </>
  )
}

export default ProviderDetails