import { qs, qsOne } from '../utils/utils';
import api from '../utils/api';

export const getAllUser = async (data) => {
    try {
        const response = await api.get(`/user/getalluser?${qs(data)}`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}   

export const totalUser = async (data) => {
    try {
        const response = await api.get(`/user/getalluser`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const createUser = async (data) => {
    try {
        const response = await api.post(`/user/create-user`, data);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateDoctor = async (data, dataset) => {
    try {
        const response = await api.put(`/doctor/update/${qsOne(data)}`, dataset);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const updateDoctorApproval = async (data) => {
    try {
        const response = await api.patch(`/doctor/bcba-certification`, data);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const getCount = async () => {
    try {
        const response = await api.get(`/get-count`);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }
}

export const sendEmailUser = async (data) => {
    try {   
        const response = await api.post(`/user/send-email`, data);
        if (response?.status === 200) {
            return response?.data;
        }
    } catch (error) {
        return { error: error?.response?.data?.message };
    }   
}
