import React, { useEffect, useState } from 'react';
import style from './countbar.module.css';
import { totalDoctor } from '../../services/ProviderService.js';
import Loading from '../loading/Loading';
import { FaUsers } from "react-icons/fa"; // User icon
import { FaUserDoctor } from "react-icons/fa6"; // Doctor icon
import { totalUser } from '../../services/UserService.js';

const CountBar = () => {
    const [doctorCount, setDoctorCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDoctorData();
        getUserData(); // Call the user data function as well
    }, []);

    const getDoctorData = async () => {
        try {
            setLoading(true);
            const response = await totalDoctor();
            setDoctorCount(response.total);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const getUserData = async () => {
        try {
            setLoading(true);
            const response = await totalUser(); // Replace with your API call for total users
            setUserCount(response.total);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="mt-sm-4 mt-2 row">
            {loading && <Loading />}
            <div className="col-xl-4 col-sm-6 pe-sm-3 pb-xl-0 pb-3">
                <div className={style.section}>
                    <div className={style.count1}>
                        <FaUserDoctor className={style.icon} /> {/* Use the doctor icon */}
                    </div>
                    <div>
                        <p className={style.content}>Total Doctors</p>
                        <h4 className={style.heading}>{doctorCount}</h4>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-6 pe-sm-3 pb-xl-0 pb-3">
                <div className={style.section}>
                    <div className={style.count1}>
                        <FaUsers className={style.icon} /> {/* Use the user icon */}
                    </div>
                    <div>
                        <p className={style.content}>Total Users</p>
                        <h4 className={style.heading}>{userCount}</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountBar;
