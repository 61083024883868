import React from 'react';
import ReactPaginate from 'react-paginate';
import style from './pagination.module.css'; // Assuming you have a CSS module for styling

const Pagination = ({ pageCount, currentPage, handlePageChange }) => {
    return (
        <div className={style.paginationContainer}>
            <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                previousLabel="< Previous"
                className={style.pagination}
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                forcePage={currentPage}
                marginPagesDisplayed={1}
                pageLinkClassName={`${style.pages} text-dark`}
                activeLinkClassName={style.activepage}
                nextClassName={style.next}
                previousClassName={style.previous}
                previousLinkClassName={style.previouslink}
                nextLinkClassName={style.nextlink}
                disabledLinkClassName={style.disable}
            />
        </div>
    );
};

export default Pagination;