import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import style from './faqform.module.css'
import success from '../../assets/Success.png'
import { addFAQ, updateFAQ } from '../../services/FAQService'
import { useNavigate, useParams } from 'react-router-dom'
import Loading from '../loading/Loading'

const FAQForm = ({ initialData }) => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [errors, setErrors] = useState({ question: '', answer: '' });
    const [form, setForm] = useState({ created_by: user?.id, question: '', answer: '' });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [error, setError] = useState('');
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors({ question: '', answer: '' });
    }, [])

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    useEffect(() => {
        setLoading(true)
        if (initialData) {
            setForm({
                created_by: user?.id,
                question: initialData?.question || '',
                answer: initialData?.answer || ''
            });
            setIsEditMode(true);
        }
        setLoading(false)
    }, [initialData]);

    const validateForm = () => {
        const { question, answer } = form;

        const newErrors = {};

        if (!question || question === "") newErrors.question = "The question field is required.";
        if (!answer || answer === "") newErrors.answer = "The answer field is required.";
        return newErrors;
    };

    const handleOnClose = () => {
        setForm({ question: '', answer: '' })
        setErrors({ question: '', answer: '' })
        navigate('/faq')
    }

    const handleOnSubmit = async () => {
        const newErrors = validateForm();

        if (Object.keys(newErrors).length === 0) {
            try {
                if (isEditMode) {
                    if (Object.keys(form).length > 0) {
                        setLoading(true)
                        const response = await updateFAQ(form, { id });
                        setLoading(false)
                        if (response?.error) {
                            setError(response.error);
                        }
                        else {
                            setError('')
                            setShowSuccessModal(true);
                        }
                    }
                    else {
                        setError('No changes detected')
                    }
                }
                else {
                    try {
                        setLoading(true)
                        const response = await addFAQ(form);
                        setLoading(false)
                        if (response?.error) {
                            setError(response.error);
                        }
                        else {
                            setError('')
                            setShowSuccessModal(true);
                            setForm({ name: '', type: 1, status: 1, created_by: user?.id }); // Reset form after adding
                        }

                    } catch (error) {
                        console.log(error);
                    }
                }
            } catch (error) {
                console.error("Error submitting faq:", error);
            }
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className='d-lg-flex gap-3'>
                    <div className={style.formsection}>
                        <Form.Group controlId='question' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Question :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.question || ''}
                                onChange={(e) => setField("question", e.target.value)}
                                placeholder='Enter question'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.question && <div className="text-danger mt-2">{errors.question}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='answer' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Answer :</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className={`${style.inputfieldtextarea} form-control mt-sm-3 mt-1`}
                                value={form?.answer || ''}
                                onChange={(e) => setField("answer", e.target.value)}
                                placeholder='Enter answer'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.answer && <div className="text-danger mt-2">{errors.answer}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                {error && <div className="text-danger mt-2 mt-4">{error}</div>}
                <div className='d-flex  gap-3  mt-4'>
                    <Button className={`${style.cancelbutton}`} onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        {isEditMode ? 'Update' : 'Submit'}
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false}>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '200px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>{isEditMode ? 'FAQ updated successfully' : 'You have successfully Created a new FAQ'}</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => {
                        setShowSuccessModal(false);
                        navigate('/faq')
                    }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default FAQForm