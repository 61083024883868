import { Outlet, Navigate } from 'react-router-dom';

export default function PrivateRoutes() {
    const authToken = localStorage.getItem('token');

    // Check if the token is missing or if the user is not an admin, then redirect to login
    if (!authToken) {
        return <Navigate to="/login" />;
    }
    
    return <Outlet />;
}