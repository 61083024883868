import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './faqdetails.module.css'
import { Accordion, Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { FiEdit } from 'react-icons/fi'
import { RiDeleteBinLine } from 'react-icons/ri'
import deletemodalimage from '../../assets/delete.png'
import { deleteOneFAQ, getFAQ, searchFAQ } from '../../services/FAQService'
import Loading from '../loading/Loading'

const FAQDetails = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const user = JSON.parse(localStorage.getItem('userdata'));
    const [deleteId, setDeleteId] = useState('');

    const params = {
        created_by: user?.id,
        question: searchQuery,
    };

    useEffect(() => {
        getFAQData();
    }, [searchQuery]);

    const getFAQData = async () => {
        try {
            setLoading(true);
            // if (searchQuery) {
            //     const response = await searchFAQ(params);
            //     setData(response?.data);
            // } else {
                const response = await getFAQ(params);
                setData(response?.data);
            // }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleOnDelete = async () => {
        try {
            await deleteOneFAQ({ id: deleteId });
            setShowModal(false);
            getFAQData();
        } catch (error) {
            console.error("Error deleting faq:", error);
        }
    };
    
    return (
        <>
            {loading && <Loading />}
            <div className='mt-sm-4 d-lg-flex mb-sm-3 mb-2 justify-content-between'>
                <div className='d-sm-flex gap-3 align-items-center'>
                    <InputGroup className={`${style.searchbar} mt-sm-0 mt-1`}>
                        <InputGroup.Text id="inputGroup-sizing-default" className={style.searchButton}>
                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.875 21.1875L17.625 15.9375C18.8438 14.3438 19.5938 12.375 19.5938 10.2188C19.5938 5.0625 15.375 0.84375 10.2188 0.84375C5.0625 0.84375 0.75 5.0625 0.75 10.2188C0.75 15.375 4.96875 19.5938 10.125 19.5938C12.2812 19.5938 14.3438 18.8438 15.9375 17.5312L21.1875 22.7813C21.375 22.9688 21.75 23.1562 22.0312 23.1562C22.3125 23.1562 22.5938 23.0625 22.875 22.7813C23.3438 22.4063 23.3438 21.6562 22.875 21.1875ZM3.09375 10.2188C3.09375 6.375 6.28125 3.1875 10.125 3.1875C13.9688 3.1875 17.1562 6.375 17.1562 10.2188C17.1562 14.0625 13.9688 17.25 10.125 17.25C6.28125 17.25 3.09375 14.1563 3.09375 10.2188Z" fill="black" />
                            </svg>
                        </InputGroup.Text>
                        <Form.Control
                            type='text'
                            className={style.inputfieldsearch}
                            placeholder='Search'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </div>
                <div className='d-sm-flex gap-3  align-items-center mt-xl-0 mt-2'>
                    <div className='d-flex mt-sm-0 m--2'>
                        <button className={`btn ${style.button} me-2`} onClick={() => navigate('/faq/add')} >
                            <svg className='me-2' width="18" height="18" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="17" cy="17" r="16.5" stroke="white" strokeDasharray="4 4" />
                                <path d="M10.5833 16.9997H23.4166M17 10.583V23.4163" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Add New
                        </button>
                    </div>
                </div>
            </div>
            <div>
                {data?.length > 0 ?
                    data?.map((record, index) => (
                        <div className='d-flex mb-2 gap-3' key={index}>
                            <Accordion className='w-75'>
                                <Accordion.Item eventKey="0" className={style.accordionitem}>
                                    <Accordion.Header className={style.accordionheader}>{record?.question}</Accordion.Header>
                                    <Accordion.Body className={style.accordionbody}>
                                        {record?.answer}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className='d-flex align-items-center justify-content-center gap-3'>
                                <FiEdit className={style.edit} onClick={() => navigate(`/faq/edit/${record?._id}`)} />
                                <RiDeleteBinLine className={style.delete} onClick={() => { setShowModal(true); setDeleteId(record?._id) }} />
                            </div>
                        </div>
                    )) :
                    <div className='d-flex align-items-center justify-content-center bg-white fs-4 rounded-3' style={{ height: '250px' }}>
                        Data Not Found
                    </div>
                }
            </div>
            <Modal size='sm' show={showModal} onHide={() => setShowModal(false)} centered backdrop='static' keyboard={false}>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={deletemodalimage} alt="Success" style={{ height: '200px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>Are you sure you want to delete this FAQ ?</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={handleOnDelete}>
                        Delete
                    </Button>
                    <Button className={style.cacelbutton} onClick={() => { setShowModal(false) }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FAQDetails