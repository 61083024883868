import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import style from './notificationform.module.css'
import success from '../../assets/Success.png'
import { useNavigate } from 'react-router-dom'
import Loading from '../loading/Loading'
import { sendEmailUser } from '../../services/UserService'

const NotificationForm = () => {
    const [errors, setErrors] = useState({ title: '', body: '', type: '' });
    const [form, setForm] = useState({ title: '', body: '', type: '' });
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors({ title: '', body: '', type: '' });
    }, [])

    const setField = (field, value) => {
        setForm((prevForm) => ({
            ...prevForm,
            [field]: value,
        }));

        if (!!errors[field]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [field]: null,
            }));
        }
    };

    const validateForm = () => {
        const { title, body, type } = form;

        const newErrors = {};

        if (!title || title === "") newErrors.title = "The title field is required.";
        if (!body || body === "") newErrors.body = "The body field is required.";
        // if (!type || type === "") newErrors.type = "The user type field is required.";
        return newErrors;
    };

    const handleOnSubmit = async () => {
        const newErrors = validateForm();

        if (Object.keys(newErrors).length === 0) {
            try {
                // setLoading(true)
                const response = await sendEmailUser({ ...form, type: 'user' });
                setLoading(false)
                if (response?.error) {
                    setError(response.error);
                } else {
                    setError('')
                    setShowSuccessModal(true);
                    setForm({ title: '', body: '', type: '' });
                }
            } catch (error) {
                console.error("Error sending email:", error);
                // setLoading(false);
            }
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className='mt-sm-4 mt-2 bg-white p-sm-4 p-2 rounded-3'>
            {loading && <Loading />}
            <Form>
                <div className='d-lg-flex gap-3'>
                    <div className={style.formsection}>
                        <Form.Group controlId='title' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Title :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.title || ''}
                                onChange={(e) => setField("title", e.target.value)}
                                placeholder='Enter title'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.title && <div className="text-danger mt-2">{errors.title}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='body' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>Body :</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className={`${style.inputfieldtextarea} form-control mt-sm-2 mt-1`}
                                value={form?.body || ''}
                                onChange={(e) => setField("body", e.target.value)}
                                placeholder='Enter body'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.body && <div className="text-danger mt-2">{errors.body}</div>}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group controlId='userType' className='mt-4'>
                            <Form.Label className={`${style.modelcontent}`}>User Type :</Form.Label>
                            <Form.Control
                                type="text"
                                className={`${style.inputfield} form-control mt-sm-2 mt-1`}
                                value={form?.userType || ''}
                                onChange={(e) => setField("userType", e.target.value)}
                                placeholder='Enter user type'
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.userType && <div className="text-danger mt-2">{errors.userType}</div>}
                            </Form.Control.Feedback>
                        </Form.Group> */}
                    </div>
                </div>
                {error && <div className="text-danger mt-2 mt-4">{error}</div>}
                <div className='d-flex gap-3 mt-4'>
                    <Button className={`${style.cancelbutton}`} onClick={() => navigate('/notification/add')}>
                        Cancel
                    </Button>
                    <Button className={`${style.submitbutton}`} onClick={handleOnSubmit}>
                        Submit
                    </Button>
                </div>
            </Form>
            <Modal size='sm' show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered backdrop='static' keyboard={false}>
                <Modal.Header className='border-0 p-0 justify-content-center'>
                    <img src={success} alt="Success" style={{ height: '200px' }} />
                </Modal.Header>
                <Modal.Body className='p-0'><h1 className={`${style.modelcontent} text-center`}>{'Email sent successfully!'}</h1></Modal.Body>
                <Modal.Footer className='py-2 px-0 border-0 justify-content-center'>
                    <Button className={style.submitbutton} onClick={() => {
                        setShowSuccessModal(false);
                    }}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NotificationForm