import React, { useEffect } from 'react'
import FAQForm from '../components/faqform/FAQForm'

const AddFAQ = ({ setHeading }) => {
    useEffect(() => {
        setHeading('Add FAQ')
    }, [])

    return (
        <>
            <div>
                <div className={`maindiv`}>
                    <FAQForm />
                </div>
            </div>
        </>
    )
}

export default AddFAQ