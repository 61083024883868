import React, { useEffect } from 'react'
import CountBar from '../components/dashboardcountbar/CountBar'
import UserTable from '../components/usertable/UserTable'

const Users = ({ setHeading }) => {
  useEffect(() => {
    setHeading('Users')
  }, [])
  
  return (
    <>
      <div>
        <div className={`maindiv`}>
          <CountBar />
          <UserTable />
        </div>
      </div>
    </>
  )
}

export default Users