import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setPaymentError(error.message);
        console.error(error.message);
      } else {
        setPaymentError(null);
        const { paymentIntent } = await fetch('https://aba-pathway-backend.onrender.com/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: 1030, // Replace with your amount
            currency: 'usd', // Replace with your currency
            paymentMethodId: paymentMethod.id,
          }),
        }).then(res => res.json());

        if (paymentIntent && paymentIntent.client_secret) {
          const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret);

          if (error) {
            setPaymentError(`Payment failed: ${error.message}`);
            console.error('Payment failed:', error.message);
          } else {
            setPaymentSuccess(true);
            console.log('Payment succeeded!');
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setPaymentError('Payment failed');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
      {paymentError && <div>{paymentError}</div>}
      {paymentSuccess && <div>Payment successful!</div>}
    </form>
  );
};

export default Payment;