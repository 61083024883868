import { Menu } from 'react-feather';
import {
	Navbar
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import style from './navbartop.module.css'

const NavbarTop = (props) => {
	const user = JSON.parse(localStorage.getItem('userdata'));
console.log(props)
	return (
		<Navbar expanded="lg" className={`navbar-classic navbar-expand-lg`} style={{ borderBottom: '1px solid #D2D2D2' }}>
			<div className='d-xl-none'>
				<Link
					href="#"
					id="nav-toggle"
					className="nav-icon me-1 icon-xs"
					onClick={() => props.data.SidebarToggleMenu(!props.data.showMenu)}>
					<Menu size="18px" />
				</Link>
			</div>
			<div className={`${style.top}`}>
				<h3 className={`${style.heading}`}>{props?.heading || ''}</h3>
				<div className={`${style.userdetail} d-flex gap-sm-3 gap-1`}>
					<div style={{ backgroundColor: '#FFFFFF', padding: '4px', borderRadius: '10px' }} className='d-flex align-items-center '>
						<img src={user?.user_profile} alt="User profile" className={style.userimage} />
						<div className='ms-2'>
							<p className={`mb-0 ${style.content}`}>{user?.name}</p>
							<p className={`mb-0 ${style.subcontent}`}>Admin</p>
						</div>
					</div>
				</div>
			</div>
		</Navbar>
	);
};

export default NavbarTop;